import {Dispatch, useEffect, useState} from 'react';
import {Col, DatePicker, Form, Input, Radio, Row, Select} from 'antd';
import {SubscriberStatus} from '../../../../../core/enums/SubscriberStatus';
import {CommodityRes} from '../../../../../core/dtos/commodityRes';
import {getAllCommodities} from '../../../../../services/commodityService';
import {SubscriberRequestFilter} from '../../../../../core/types/SubscriberRequestFilter';
import ModalFilterLayout from '../../../../../components/Modals/ModalFilterLayout';

export default function ModalSubscribersFilter({
  isOpen,
  setIsOpen,
  changeFilter,
  form
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>
  changeFilter: (changedFilter: Partial<SubscriberRequestFilter>) => void,
  form: any
}) {
  const [commodities, setCommodities] = useState<CommodityRes[]>([]);

  useEffect(() => {
    getAllCommodities()
      .then((commodities) => {
        setCommodities(commodities);
      });
  }, []);
    
  return (
    <ModalFilterLayout header='Filter Subscribers' isOpen={isOpen} setIsOpen={setIsOpen} onClose={() => changeFilter(form.getFieldsValue())}>
      <Form name='Subscribers' form={form} layout='vertical'>
        <Row gutter={[18, 0]}>
          <Col xs={8} style={{marginTop: '16px'}}>
            <Form.Item
              name='name'
              label='Name'
            >
              <Input placeholder="Search in name" size='large' />
            </Form.Item>
          </Col>
          <Col xs={8} style={{marginTop: '16px'}}>
            <Form.Item
              name='username'
              label='Username'
            >
              <Input placeholder="Search in username" size='large' />
            </Form.Item>
          </Col>
          <Col xs={8} style={{marginTop: '16px'}}>
            <Form.Item
              name='email'
              label='Email'
            >
              <Input placeholder="Search in email" size='large' />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='commodities'
              label='Commodities'
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select commodities"
                size='large'
                fieldNames={{ value: '_id', label: 'name' }}
                filterOption={(input: string, option: any) =>
                  (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={commodities?.sort()}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name='status'
              label='Status'
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select status"
                size='large'
                options={[
                  {
                    value: SubscriberStatus.PAID,
                    label: 'Paid'
                  },
                  {
                    value: SubscriberStatus.FREE_TRIAL,
                    label: 'Free Trial'
                  },
                  {
                    value: 'EXPIRED',
                    label: 'Expired'
                  }
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name='createdAt'
              label='Created At'
            >
              <DatePicker.RangePicker
                size="large"
                style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name='validTill'
              label='Valid Till'
            >
              <DatePicker.RangePicker
                size="large"
                style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} style={{display: 'flex', alignItems:'center', gap:'8px'}}>
            <Form.Item
              name='isBlocked'
              label='Blocked'
            >
              <Radio.Group size="large">
                <Radio.Button value={false}>No</Radio.Button>
                <Radio.Button value={undefined} defaultChecked>Default</Radio.Button>
                <Radio.Button value={true}>Yes</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalFilterLayout>
  );
}
